<template>
  <img
    src="~assets/image/tab_banner/thumbnail/business_thumbnail.jpg"
    alt="主营业务"
    class="page-banner"
    style="width: 100%"
    v-lazyLoadImage="require('assets/image/tab_banner/business.png')"
  />
  <div class="content">
    <div class="left-nav"><tab-menu :menu-list="menuList" /></div>
    <div class="right-contact">
      <div class="title-box"><point-title :title="title_name" /></div>
      <router-view />
    </div>
  </div>
</template>

<script>
import TabMenu from "components/TabMenu.vue";
import { useRoute } from "vue-router";
import { computed, watch } from "vue";
import PointTitle from "@/components/PointTitle.vue";
import { goToPageTop } from "util";
const menuList = [
  {
    name: "大气环境",
    children: [
      { name: "硬件产品", uri: "/Hardware" },
      { name: "集成能力建设与解决方案", uri: "/Software" },
    ],
  },
  {
    name: "绿色发展",
    children: [
      { name: "生态环境监测", uri: "/Ecotope" },
      { name: "生态环境数据信息化", uri: "/Weather" },
    ],
  },
  {
    name: "智慧气象",
    children: [
      { name: "政府气象", uri: "/SucCase" },
      { name: "航空气象", uri: "/Aviation" },
      { name: "海洋气象", uri: "/Ocean" },
    ],
  },
  {
    name: "咨询服务",
    children: [
      { name: "颗粒物、VOCs源解析", uri: "/ParticulateMatter" },
      { name: "城市空气质量达标规划", uri: "/AirQuality" },
      { name: "三线一单编制", uri: "/SingleSystem" },
      { name: "综合分析报告编制", uri: "/Comprehensive" },
      { name: "大气污染防治第三方绩效服务", uri: "/Performance" },
      { name: "重大会议活动空气质量保障服务", uri: "/Assurance" },
    ],
  },
];
export default {
  components: { TabMenu, PointTitle },
  name: "Business",
  setup() {
    const route = useRoute();
    const title_name = computed(() => route.meta.name);

    if (route.query.isHome) {
      goToPageTop(60);
    }
    // watch(title_name, () => goToPageTop());
    return { title_name, menuList };
  },
};
</script>

<style lang="scss" scoped>
.content {
  // height: 1048px;
  width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  padding: 30px 0 80px 0;
  box-sizing: border-box;
  .left-nav {
    width: 230px;
    background-color: #fff;
  }
  .right-contact {
    width: 930px;
    // height: 938px;
    margin-left: 40px;
    background-color: #fff;
    position: relative;
    .title-box {
      padding: 15px 0 15px 20px;
      border-bottom: 1px solid #f4f4f4;
    }
  }
}
</style>
>
